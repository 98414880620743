import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Motorcycle Accidents",
    description:
      "If you've been in a motorcycle accident, get fair compensation. Contact Bartlett & Grippe, LLC for your rights and get help winning a settlement that covers your pain and suffering.",
    heroH1: "Connecticut Motorcycle Accidents",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "hero-motorcycle.jpg",
    heroImageAlt: "A car approach a motorcycle on a road",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              We Are Connecticut Motorcycle Accident Lawyers – Injured?
            </h2>
            <h3 className="uppercase">
              We help a fellow rider through the legal process.
            </h3>
            <p className="mb-8">
              Being involved in a motorcycle accident can cause lasting damage
              that will stay with a person throughout the rest of their life.
              That’s true for even minor accidents, as they can cause pain and
              suffering that’s not easily removed. If you’ve been in an
              accident, it’s time to contact Bartlett & Grippe, LLC in order to
              find out your rights and see what type of compensation is fair to
              you.
            </p>
            <p className="mb-8">
              The other party’s insurance company is going to want to settle for
              as little as possible, but you don’t have to accept their offer.
              Get our motorcycle accident attorneys to help you make your case
              and win a fair settlement that adequately compensates you for your
              pain and suffering.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Motorcycle Traffic Accident Lawyers Can Help You
            </h2>
            <p className="mb-8">
              People who ride motorcycles should be able to feel safe on the
              roads. Unfortunately, sometimes something goes wrong and another
              driver doesn’t see the motorcyclist. There’s an accident, and
              because of the lack of protection a person has when riding a
              motorcycle there can be significant injuries even from a low-speed
              collision. Broken bones, damaged skin, and internal or head
              injuries are not uncommon when a person is involved in a
              motorcycle accident.
            </p>
            <p className="mb-8">
              Fortunately, there is help available so you can financially handle
              the aftermath of the accident. That can include medical bills and
              repairs to the motorcycle or replacement of it, but there may be
              other expenses. Lost wages are a common expense after an accident,
              as is physical therapy. Additionally, you may have mental and
              emotional trauma from the motorcycle accident that will take some
              time to work through. If you’ve been permanently injured or
              disabled from the accident, that should also be compensated,
              contact a good attorney as soon as possible.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Hire the Right Attorney for Your Case</h2>
            <p className="mb-8">
              Our top rated motorcycle attorneys are prepared to handle your
              motorcycle accident case. With years of experience, as motorcycle
              riders and in the courtroom, we are aware of the devastation
              traffic accidents of all types can cause for those involved in
              them. That’s why proper representation and compensation are so
              important, so you can get what you deserve and protect your assets
              from medical bills and other expenses that can come from an
              accident.
            </p>
            <p className="mb-8">
              Don’t settle for what the other party’s insurance company wants to
              give you. You deserve fair compensation for the damage incurred.
              The right attorney can make sure the settlement you receive truly
              compensates you for the accident.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
